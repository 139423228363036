(function($, prodcat) {
  $(document).on('product.sppInit', '.js-product-ui', function(event, prodId) {
    var prodData = prodcat.data.getProduct(prodId);
    var skuBaseId;

    // Route callbacks.
    var routerDeriveRoute = {
      deriveSku: function(checkSku) {
        var skuData = prodcat.data.getSku(checkSku);
        if (skuData) {
          skuBaseId = skuData.SKU_BASE_ID;
        }
      },
      deriveSppShade: function(shadeName) {
        skuBaseId = getSkuIdFromShade(shadeName);
      }
    };

    function getSkuIdFromShade(shadeName) {
      prodcat.ui.routeNoSpaces = decodeURIComponent(shadeName).split('_').join(' ');
      var skuMatchArray = prodData.skus.filter(function(sku) {
        return prodcat.ui.routeNoSpaces === sku.SHADENAME;
      });
      return skuMatchArray.length ? skuMatchArray[0].SKU_BASE_ID : skuBaseId;
    }

    // Establish route listeners and initialize.
    var routes = {
      '/shade/:shadeName': routerDeriveRoute.deriveSppShade,
      '/sku/:skuID': routerDeriveRoute.deriveSku
    };
    var router = Router(routes);
    router.init(); // This will try to derive the active sku from the URL and set skuBaseId.
    var shadeRoutefromURL = getUrlParam('shade');

    if (!!shadeRoutefromURL) {
      skuBaseId = getSkuIdFromShade(shadeRoutefromURL);
    }

    // If routing didn't determine skuBaseId derive from product
    if (!skuBaseId && !!prodData && prodData.skus.length) {
      skuBaseId = findShoppableSku(prodData);
    }

    function getUrlParam(name) {
      var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
      var value = null;
      if (!!results) {
         value = decodeURI(results[1]) || 0;
      }
      // make sure there aren't any other url params stick to the value of the wanted one
      value = value && value.split(/[?#]+/).length > 1 ? value.split(/[?#]+/)[0] : value;
      return value;
    }

    // If we have established a skuBaseId from either routing or default, trigger skuSelect.
    if (!!skuBaseId) {
      $(this).trigger('product.skuSelect', skuBaseId);
    } else {
      // update inventory status
      $(this).trigger('product.updateInvStatus', prodData);
    }

    // Helper function for fallback to default sku.
    function findShoppableSku(prodData) {
      var shoppableSku = null;

      if (prodData.defaultSku && prodData.defaultSku.isShoppable) {
        return prodData.defaultSku.SKU_BASE_ID;
      }

      if (prodData.skus.length > 1) {
        var validSku = _.find(prodData.skus, {
          isShoppable: 1
        });
        shoppableSku = !!validSku ? validSku.SKU_BASE_ID : skuBaseId;
      }

      return shoppableSku;
    }
  });
})(jQuery, window.prodcat = window.prodcat || {});
